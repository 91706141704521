'use client';

import { IMG_BASE } from '@nm-namshi-frontend/core/config';
import Button from '@nm-namshi-frontend/core/components/Button';

import styles from './Error.module.scss';

// Since this page is for errors that are in the root level , we will default to EN for now
export default function Error({ error, reset }: { error: Error; reset: () => void }) {
    console.log('Error Boundary in Root:', error);
    return (
        <div className={styles.container}>
            <img
                src={`${IMG_BASE}/sivvi/design-system/empty-states/error-general.svg`}
                alt="Error"
                width={120}
                height={120}
            />
            <h2>Oh no! Something broke</h2>
            <p>Sorry about this, please bear with us as we try to sort this out</p>
            <Button onClick={() => window.location.reload()} size="small" customStyle={styles.button}>
                Try Again
            </Button>
        </div>
    );
}
