import React from 'react';

import styles from './Loader.module.scss';

type TLoaderProps = {
    size?: number;
    color?: string;
};

const Loader: React.FC<TLoaderProps> = ({ color = '#000', size = 20 }) => (
    <div
        className={styles.container}
        style={{
            height: size,
            width: size,
            backgroundColor: color,
        }}
    />
);

export default Loader;
