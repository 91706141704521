import React, { forwardRef } from 'react';

import clsx from 'clsx';

import Loader from '@nm-namshi-frontend/core/components/loaders/Loader';

import styles from './Button.module.scss';

export type TButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'subdued';
type TButtonSizes = 'small' | 'medium' | 'large';

type TProps = {
    children: React.ReactNode;
    customStyle?: string;
    disabled?: boolean;
    inline?: boolean;
    loading?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => void;
    size?: TButtonSizes;
    tabIndex?: number;
    type?: 'button' | 'submit';
    variant?: TButtonVariants;
    buttonStyles?: string;
};

const Button = forwardRef(
    (
        {
            buttonStyles,
            children,
            customStyle,
            disabled,
            inline = false,
            loading,
            onClick,
            size = 'medium',
            tabIndex,
            type,
            variant = 'primary',
        }: TProps,
        ref: React.ForwardedRef<HTMLButtonElement>,
    ): JSX.Element => (
        <button
            ref={ref}
            className={clsx(
                styles.button,
                loading && styles.loading,
                inline && styles.inline,
                size === 'small' && styles.small,
                size === 'medium' && styles.medium,
                size === 'large' && styles.large,
                variant === 'primary' && styles.primary,
                variant === 'secondary' && styles.secondary,
                variant === 'tertiary' && styles.tertiary,
                variant === 'subdued' && styles.subdued,
                customStyle,
                buttonStyles,
            )}
            disabled={disabled || loading}
            onClick={onClick}
            tabIndex={tabIndex}
            type={type === 'submit' ? 'submit' : 'button'}
        >
            <span className={clsx(styles.content)}>{children}</span>
            {loading && (
                <span className={styles.loadingContainer}>
                    <Loader
                        color={['tertiary'].includes(variant) ? 'var(--clr-white)' : 'var(--clr-dark)'}
                        size={size === 'medium' ? 24 : 18}
                    />
                </span>
            )}
        </button>
    ),
);

Button.displayName = 'Button';

export default Button;
